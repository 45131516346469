<template>
  <div class="row justify-content-center align-items-md-center">
    <div class="col-md-8 col-12">
      <div class="card full-height bg-ligth">
        <div class="card-header">
          <h3 class="mb-0">Servicos e Custos</h3>
        </div>
        <div class="card-body table-responsive">
          <div class="col-md-6 mb-3">
                  <label class="col-md-12">Eventos:*</label>
                  <treeselect
                    :multiple="false"
                    :options="lista_eventos"
                    placeholder="Selecione o evento..."
                    v-model="eventos_id"
                  />
                </div>
          <b-table
          responsive
              :fields="['evento','descricao','quant','valor_un_evento','total', 'acoes']"
              :items="lista_servicos"
              :per-page="perPage"
              :current-page="currentPage"
              id="pdvs_evento-table"
              class="table table-head-custom  table-vertical-center table-head-bg table-borderless"
              show-empty
              empty-text="Nenhum registro encontrado!"
          >
          <template #head(acoes)><span></span></template>
          <template #cell(evento)="{ item }"><span>{{item.evento }} {{item.eventos_id }}</span></template>

          <template #cell(acoes)="{ item }">
              <div class="text-right w-100">
                <v-tooltip
                  v-for="(btn, index) in lista_btn"
                  :key="index"
                  top
                  :color="btn.color"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                     v-show="lista_permissoes_filial[btn.permisao]"
                      
                      outlined
                      class="mr-1"
                      @click="acoes(item, btn.button)"
                      :color="btn.color"
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <i :class="btn.icon"></i>
                    </v-btn>
                  </template>
                  <span>{{ btn.title }}</span>
                </v-tooltip>
              </div>
            </template>
          </b-table>
          <b-pagination
              v-model="currentPage"
              :total-rows="lista_servicos.length"
              :per-page="perPage"
              aria-controls="pdvs_evento-table"
          >
          </b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/store/breadcrumbs.module";
import EventoService from "@/core/services/evento.service";

export default {
  data() {
    return {
      eventos_id:null,
          currentPage: 1,
      perPage: 10,
      lista_btn: [
        //    {
        //   permisao:"c_Pdv",
        //   button: "entrar",
        //   title: "Gerenciar Evento",
        //   color: "warning",
        //   icon: "fa fa-arrow-right text-warning",
        // },
        {
          permisao:"u_Pdv",
          button: "editar",
          title: "Editar registro",
          color: "info",
          icon: "far fa-edit text-primary",
        },
        {
          permisao:"lock_Pdv",
          button: "exclusao",
          title: "Bloquear/Desbloquear registro",
          color: "error",
          icon: "fas fa-trash text-danger",
        },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: "Notas e Servicos"}]);
  },

  created() {
    this.listar_servicos();
  },
  computed: {
    lista_eventos() {
      return EventoService.getEventos().map((cat) => ({
        id: cat.id,
        label: cat.id + " - " + cat.nome_evento,
      }));
    },
  lista_servicos() {
      // return this.$store.state.pdvsEvento.lista_pdvs_eventos;

    return  this.$store.state.servicos.lista_servicos.filter(
        (item) => {
          if (!this.eventos_id) return true;
          return (
            String(item['eventos_id'])
              .toLowerCase()
              .indexOf(this.eventos_id+''.toLowerCase()) !== -1
          );
        }
      );
    },

    lista_permissoes_filial() {
      return this.$store.state.sistema.lista_permissoes_filial;
    },
    mensagem_alert() {
      return this.$store.state.servicos.mensagem_alert;
    },
  },
  methods: {
    acoes(item, tipo) {
      if (tipo == "editar") this.atualizar(item);
      if(tipo == "exclusao") this.confirm(item);
      if(tipo == "entrar") this.entrar(item);

    },
    async listar_servicos() {
      await this.$store.dispatch("evento/listar_eventos");

      await this.$store.dispatch("servicos/listar_servicos")
        this.$store.dispatch("configEmpresa/atualizar", "");
    },
    atualizar(value) {
      this.$router.push({name: "createServicos"});
      this.$store.dispatch("configEmpresa/atualizar", value);
    },
    async confirm(value) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação bloqueara este Categoria no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.bloquear(value);
          }
        },
      });
    },
    async bloquear(value) {
      await this.$store.dispatch("pdv/bloquear_categoria", value);
      this.alertMessage(this.mensagem_alert.tipo, this.mensagem_alert.message);
    },
    alertMessage(tipo, mensagem) {
      this.$fire({
        title: "Aviso",
        text: mensagem,
        type: tipo,
        timer: 3000,
      }).then((r) => {
        console.log(r.value);
      });
    },

    entrar(item){
      this.$router.push({
        name: "incresosPdv",
      params: { pdvs_evento_id: item.id },
    });

    }
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>